@import '~bootstrap/scss/_functions.scss';
@import '~@sncf/bootstrap-sncf.metier/src/scss/common/settings/_variables-lightmode.scss';

@font-face {
  font-family: Avenir;
  src: url('../node_modules/@sncf/bootstrap-sncf.metier/src/assets/fonts/avenir/avenir-black.woff');
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: url('../node_modules/@sncf/bootstrap-sncf.metier/src/assets/fonts/avenir/avenir-book.woff');
  font-weight: 400;
}

@font-face {
  font-family: Avenir;
  src: url('../node_modules/@sncf/bootstrap-sncf.metier/src/assets/fonts/avenir/avenir-medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: url('../node_modules/@sncf/bootstrap-sncf.metier/src/assets/fonts/avenir/avenir-black.woff');
  font-weight: 900;
}

$font-size-base: 16px;

:export {
  colorBlue: $blue;
  colorPurple: $purple;
  colorPink: $pink;
  colorRed: $red;
  colorOrange: $orange;
  colorYellow: $yellow;
  colorGreen: $green;
  colorTeal: $teal;
  colorCyan: $cyan;
  colorCarbon: $carbon;
  colorGray: $gray-600;
  colorGrayDark: $gray-800;
  fontFamilySansSerif: $font-family-sans-serif;
  fontFamilyMonospace: $font-family-monospace;
  fontFamilyBase: $font-family-sans-serif;
  fontSizeBase: $font-size-base;
  fontSizeLg: $font-size-lg;
  fontSizeSm: $font-size-sm;
  fontSizeXs: $font-size-xs;

  borderRadius: $border-radius;

  cardMargin: 0.4em;
  cardBorderWidth: $card-border-width;
  cardBackground: $card-bg;
}
