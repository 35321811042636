html {
  font-size: 16px;
}

html,
body,
#chat {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: white;
}

ul {
  flex-grow: 0 !important;
}

div[aria-live^="polite"] {
  margin-top: 10px;
}

li {
  margin-top: auto !important;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

